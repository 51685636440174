<template>
  <vx-card>
    <div class="vx-row ml-2 mb-2">
      <h4>Collection Plan</h4>
    </div>

    <div class="vx-row my-4">
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleOnRoute"
          >On Route</vs-button
        >
      </div>
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleOffRoute"
          >Off Route</vs-button
        >
      </div>
    </div>

    <div class="vx-row my-4">
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="warning"
          icon-pack="feather"
          icon="icon-download"
          @click="handleExport('excel')"
          >Multiple Export to Excel</vs-button
        >
      </div>
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="primary"
          icon-pack="feather"
          icon="icon-printer"
          @click="handleExport('pdf')"
          >Multiple Export to Pdf</vs-button
        >
      </div>
    </div>
    <vs-tabs>
      <vs-tab label="Released">
        <div class="tab-text">
          <released-index ref="table" @data="getTableIds"></released-index>
        </div>
      </vs-tab>
      <vs-tab label="Wait Approval">
        <div class="tab-text">
          <wait-approval-index
            ref="table"
            @data="getTableIds"
          ></wait-approval-index>
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="tab-text">
          <canceled-index ref="table" @data="getTableIds"></canceled-index>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import ReleasedIndex from "./_tabs/released/index";
import CanceledIndex from "./_tabs/canceled/index";
import WaitApprovalIndex from "./_tabs/wait-approval/index";

export default {
  components: {
    ReleasedIndex,
    WaitApprovalIndex,
    CanceledIndex,
  },
  data() {
    return {
      tableIds: [],
    };
  },
  methods: {
    handleOnRoute() {
      this.$router.push({ name: "collection-plan-new-on-route" });
    },
    handleOffRoute() {
      this.$router.push({ name: "collection-plan-new-off-route" });
    },

    handleExport(type) {
      if (this.tableIds.length > 0) {
        if (type == "pdf") {
          this.$refs.table.doMultipleExportPdf(type, this.tableIds);
        } else if (type == "excel") {
          this.$refs.table.doMultipleExport(type, this.tableIds);
        }
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one data",
          color: "danger",
        });
      }
    },
    getTableIds(data) {
      this.tableIds = data;
    },
  },
};
</script>

<style>
th {
  position: sticky;
  top: 0;
  background: white;
}
</style>
<template>
  <vx-card :title="title">
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <vs-table
          search
          stripe
          border
          description
          multiple
          maxHeight="500px"
          v-model="selecteds"
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="onSearchTable"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="5%">Action</vs-th>
            <vs-th>No</vs-th>
            <vs-th>Collection Plan ID</vs-th>
            <vs-th>Collection Type</vs-th>
            <vs-th>Collection Date</vs-th>
            <vs-th>Operating Unit Territory</vs-th>
            <vs-th>Sales Name</vs-th>
            <vs-th># of Invoice</vs-th>
            <vs-th>Total Invoice</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <div class="vx-row gap-2">
                  <vs-button
                    @click="onClickShow(tr.id)"
                    size="small"
                    color="success"
                    icon-pack="feather"
                    icon="icon-eye"
                  />
                </div>
              </vs-td>
              <vs-td>{{ table.rowNumberStart + indextr }}</vs-td>
              <vs-td>{{ tr.uuid }}</vs-td>
              <vs-td>{{ tr.type }}</vs-td>
              <vs-td>{{ tr.date | formatDate }}</vs-td>
              <vs-td>{{ tr.operating_unit_territory }}</vs-td>
              <vs-td>{{ tr.sales_name }}</vs-td>
              <vs-td>{{ tr.count_invoice }}</vs-td>
              <vs-td>{{ tr.total_value | formatNumber }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { formatNumber } from "../../../../../utils/common";
import logoSDN from "../../../../../assets/images/logo/logo-sdn.png";

export default {
  components: {},
  data() {
    return {
      title: "Rejected",
      baseUrl: "api/sfa/v1/collection-plan/list-collection-plan",
      selecteds: [],
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        rowNumberStart: 1,
      },
    };
  },
  filters: {
    formatNumber(value) {
      if (value) {
        return formatNumber(value);
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  watch: {
    selecteds(v) {
      this.selecteds = v;
      let ids = [];
      v.forEach((element) => {
        ids.push(element.id);
      });
      this.$emit("data", ids);
    },
  },
  methods: {
    onClickShow(id) {
      this.$router.push({
        name: "collection-plan-show",
        params: { id: id },
      });
    },
    onSearchTable(search) {
      this.table.search = search;
      this.table.page = 1;
      this.getData();
    },
    handleEdit(id) {
      this.$router.push({
        name: "collection-plan-edit",
        params: { id: id },
      });
    },
    handleShow(id) {
      this.$router.push({
        name: "collection-plan-show",
        params: { id: id },
      });
    },
    handleOnRoute() {
      this.$router.push({ name: "collection-plan-new-on-route" });
    },
    handleOffRoute() {
      this.$router.push({ name: "collection-plan-new-off-route" });
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    handleCreate() {
      this.id = null;
      this.detail = true;
    },
    doMultipleExport(type, tableIds) {
      this.$vs.loading();
      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `COLLECTION_PLAN_REPORT_MULTIPLE_${currentDate}`;
      // tableIds: [1,2,3] to ids[]=1&ids[]=2&ids[]=3
      tableIds = tableIds.map((id) => `ids[]=${id}`).join("&");
      this.$http
        .get(
          `api/sfa/v1/collection-plan/export-collection-v2/${type}?types=multiple&${tableIds}`,
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream",
            },
          }
        )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileTitle = fileTitle + ".xlsx";

            fileLink.setAttribute("download", fileTitle);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    doMultipleExportPdf(type, tableIds) {
      this.$vs.loading();
      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `COLLECTION_PLAN_REPORT_MULTIPLE_${currentDate}`;
      // tableIds: [1,2,3] to ids[]=1&ids[]=2&ids[]=3
      type = "abc";
      tableIds = tableIds.map((id) => `ids[]=${id}`).join("&");
      this.$http
        .get(
          `api/sfa/v1/collection-plan/export-collection-v2/abs?types=multiple&${tableIds}`
        )
        .then((resp) => {
          this.$vs.loading.close();

          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            let data = resp.data;
            const doc = new jsPDF("l", "mm", [297, 210]);
            // doc.addImage(logoSDN, "PNG", 20, 5, 50, 20);

            const collectionPlans = data; // Array of collection plan data
            collectionPlans.forEach((collectionPlan, index) => {
              if (index > 0) {
                doc.addPage();
              }
              let pageHeight = doc.internal.pageSize.getHeight();
              let currentY = 45;

              let collectionPlanLines = collectionPlan.dafins;

              // const createdAt = this.dateFormat(collectionPlan.date);
              let date = moment().format("DD-MM-YYYY");

              let fontSize = 9;
              doc.addImage(logoSDN, "PNG", 20, 5, 50, 20);

              doc.setFont("helvetica").setFontSize(fontSize);

              doc.text("Daftar Tagihan Kolektor/Sales", 145, 10, {
                align: "center",
              });
              collectionPlan.no_dafin
                ? doc.text("Code:" + collectionPlan.no_dafin, 280, 25, {
                    align: "right",
                  })
                : doc.text("Code:", 216, 25, {
                    align: "right",
                  }),
                doc.text("Branch:" + collectionPlan.branch, 238, 30, {
                  align: "right",
                });
              doc.text(
                "Kolektor / Salesman:" + collectionPlan.collector,
                261,
                35,
                {
                  align: "right",
                }
              );
              doc.text(
                "Tanggal RHP:" +
                  moment(String(collectionPlan.date)).format("DD MMM YYYY"),
                245,
                40,
                {
                  align: "right",
                }
              );

              fontSize = 9;
              doc.setFont("helvetica").setFontSize(fontSize);

              const head = [
                [
                  "no",
                  "Cust Kode",
                  "Cust Name",
                  "Tgl JT Faktur",
                  "No. Faktur / Tukar Faktur",
                  "Nilai Faktur / (Sisa Faktur)",
                  "Uang Tunai",
                  "Nominal Giro",
                  "Nama Bank",
                  "No. BG",
                  "Nama Bank Giro",
                  "Tgl JT Tempo",
                  "Potongan Promo / Retur Dll",
                  "Keterangan",
                ],
              ];

              let line = [];

              // console.log("v1", writeOffLines[0])
              // console.log("v2", writeOffLines[1])

              let total = 0;
              let no = 1;
              collectionPlanLines.map((v, i) => {
                console.log("LLL", v);
                line.push([
                  no++,
                  v.customer_code,
                  v.customer_name,
                  moment(String(v.due_date)).format("DD.MM.YYYY"),
                  v.no_document,
                  v.faktur_value
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  v.cash_value
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  v.giro_value
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  v.bank_name,
                  v.no_bg,
                  v.bank_giro_name,
                  moment(String(v.due_date)).format("DD.MM.YYYY"),
                  v.deduction_value,
                  v.reason,
                ]);

                total += v.bill_total;
                console.log("total disini", total);

                this.total = total;
              });

              line.push([
                { content: "Total ", colSpan: 5 },
                {
                  content: collectionPlan.bill_total_value
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  halign: "right",
                },
              ]);

              autoTable(doc, {
                startY: 45,
                head: head,
                body: line,
                margin: { right: 7, left: 7 },
                theme: "grid",
                columnStyles: {
                  0: {
                    fontSize: 8,
                    cellWidth: 8,
                  },
                  1: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 22,
                  },
                  2: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 23,
                  },
                  3: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 15,
                  },
                  4: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 18,
                  },
                  5: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 25,
                  },
                  6: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 24,
                  },
                  7: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 24,
                  },
                  8: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 20,
                  },
                  9: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 20,
                  },
                  10: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 20,
                  },
                  11: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 15,
                  },
                  12: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 25,
                  },
                  13: {
                    halign: "middle",
                    fontSize: 8,
                    cellWidth: 26,
                  },
                },
              });

              //   autoTable(doc, {
              //     startY: 180,
              //     head: [],
              //     body: [["Terbilang ", ": " + angkaTerbilang(total.toFixed(2)).toUpperCase() + " RUPIAH"]],
              //   });
              currentY = doc.lastAutoTable.finalY + 20;

              if (currentY + 25 > pageHeight - 20) {
                // Tambahkan halaman baru
                doc.addPage();
                currentY = 20; // Reset posisi vertikal pada awal halaman baru
              }

              doc.text("Diserahkan (Faktur)", 20, currentY);
              doc.text("(Admin AR)", 25, currentY + 25);

              doc.text("Diperiksa", 65, currentY);
              doc.text("(BAS)", 68, currentY + 25);

              doc.text("Diterima (Faktur)", 90, currentY);
              doc.text("(ASM/ASS)", 95, currentY + 25);

              doc.text("Diserahkan", 127, currentY);
              doc.text("(Kolektor / Sales)", 125, currentY + 25);

              doc.text("Diterima (Hasil Tagihan)", 155, currentY);
              doc.text("(Kasir)", 165, currentY + 25);

              doc.text("Diterima (Faktur Kembali)", 195, currentY);
              doc.text("(Admin AR)", 205, currentY + 25);

              doc.text("Diketahui", 245, currentY);
              doc.text("(BAS)", 247, currentY + 25);

              currentY += 25;
            });
            doc.save("collection-plan" + fileTitle + ".pdf");
            this.$vs.loading.close();
          }
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status: 99,
          },
        })
        .then((resp) => {
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.table.rowNumberStart = resp.data.row_number_start;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
